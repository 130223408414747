import { defineRule, configure } from 'vee-validate';
// import {required } from '@vee-validate/rules';
// import { localize } from '@vee-validate/i18n';
// import { localize } from 'vee-validate';
//  import en from '@vee-validate/i18n/dist/locale/en.json';
// import ru from '@vee-validate/i18n/dist/locale/ru.json';
//
// Object.keys(rules).forEach(rule => {
//   defineRule(rule, rules[rule]);
// });
export function initVeeValidate() {
 // defineRule('required', required);

// Object.keys(rules).forEach(rule => {
//   defineRule(rule, rules[rule]);
// });
  // Updating default vee-validate configuration
  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
    // generateMessage: localize('en', {
    //   messages: {
    //     required: 'This field iasdasds required',
    //   },
    // }),

  });
  if(localStorage.getItem('locale')) {
    const lang:any = localStorage.getItem('lang')
    //setLocale('en');
  }else {
    //setLocale('en');
  }
}
