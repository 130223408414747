import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import JwtService from "@/core/services/JwtService";
import {User} from "@/store/modules/AuthModule";
import store from "@/store";


@Module
export default class ApiModule extends VuexModule {

    pages = {};
    categories = {};
    page_by_slug = {};
    user ={};

    /**
     * Get authentification errors
     * @returns array
     */
     get getPagesList() {
        return this.pages;
    }
    get getCategoriesList() {
        return this.categories;
    }

    get getSinglePageBySlug() {
         return this.page_by_slug
    }

    get getUserInfoAll() {
        return this.user;
    }


    @Mutation
    [Mutations.SET_PAGES](data) {
        this.pages = Object.assign({},data);
    }

    @Mutation
    [Mutations.GET_USER_INFO](data) {
        this.user = Object.assign({},data);
    }

    @Mutation
    [Mutations.SET_PAGE_BY_SLUG](data) {
        this.page_by_slug = Object.assign({},data);
    }

    @Mutation
    [Mutations.SET_CATEGORIES](data) {
        this.categories = Object.assign({},data);
    }

    @Action
    [Actions.SEARCH](key) {
        const params = {
            locale:window.localStorage.getItem('lang'),
            ...key

        };
        return new Promise((resolve, reject) => {
            ApiService.query("/search-events-by-title-and-date", {params})
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });

    }
    @Action
    [Actions.GET_USER_ORDERS]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            const params = {
                locale:window.localStorage.getItem('lang')
            };

            return new Promise((resolve, reject) => {
                ApiService.query("/user/orders", {params})
                    .then(({ data }) => {
                       // this.context.commit(Mutations.GET_USER_INFO, data.data);
                        resolve(data)
                    })
                    .catch(({response}) => {
                        this.context.commit(Mutations.SET_ERROR, response);
                        reject(response);
                    });
            });
        }
    }
    @Action
    [Actions.GET_USER_WISHLIST]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            const params = {
                locale:window.localStorage.getItem('lang')
            };

            return new Promise((resolve, reject) => {
                ApiService.query("/get-followed-project-event", {params})
                    .then(({ data }) => {
                       // this.context.commit(Mutations.GET_USER_INFO, data.data);
                        resolve(data)
                    })
                    .catch(({response}) => {
                        this.context.commit(Mutations.SET_ERROR, response);
                        reject(response);
                    });
            });
        }
    }

    @Action
    [Actions.SET_USER_WISHLIST](data) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
        }
        const params = {
            locale: window.localStorage.getItem('lang'),
            ...data
        };
        return new Promise((resolve, reject) => {
            ApiService.query("/follow-project-event", {params})
                .then(({ data }) => {
                    resolve(data)
                })
                .catch(({ err }) => {
                    this.context.commit(Mutations.SET_ERROR, err);
                    reject();
                });
        });

    }

    @Action
    [Actions.GET_USER]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            const params = {};

        return new Promise((resolve, reject) => {
            ApiService.query("/user-profile-data", {params})
                .then(({ data }) => {
                    this.context.commit(Mutations.GET_USER_INFO, data.data);
                    resolve(data)
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
        }
    }

    @Action
    [Actions.EDIT_USER](params) {
            return new Promise((resolve, reject) => {
                ApiService.post("/update-user-profile-data", params)
                    .then(({ data }) => {
                        resolve(data)
                    })
                    .catch(({response}) => {
                        this.context.commit(Mutations.SET_ERROR, response.data);
                        reject(response);
                    });
            });

    }



    @Action
    [Actions.GET_PAGES]() {
        const params = {
            locale:window.localStorage.getItem('lang')
        };
        ApiService.query("/page-title-without-bonuses", {params})
            .then(({ data }) => {
                this.context.commit(Mutations.SET_PAGES, data.data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }

    @Action
    [Actions.GET_CATEGORIES]() {
        const params = {
            locale:window.localStorage.getItem('lang')
        };
        ApiService.query("/category-title", {params})
            .then(({ data }) => {
                this.context.commit(Mutations.SET_CATEGORIES, data.data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }

    @Action
    [Actions.GET_PAGE_BY_SLUG](slug) {
        const params = {
            locale:window.localStorage.getItem('lang'),
        };
        return new Promise((resolve, reject) => {
            ApiService.query("/" + slug, {params})
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_CATEGORY_BY_ID](id) {
        const params = {
            locale:window.localStorage.getItem('lang'),
            category:id,
        };
        return new Promise((resolve, reject) => {
            ApiService.query("/events-data-by-category", {params})
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_EVENT_BY_ID](id) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
        }
        const params = {
            locale:window.localStorage.getItem('lang'),
            id:id,
        };
        return new Promise((resolve, reject) => {
            ApiService.query("/project-event-data", {params})
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_SLIDER_EVENTS](payload) {
        const params = {
            locale:window.localStorage.getItem('lang'),
            ...payload
        };
        return new Promise((resolve, reject) => {
            ApiService.query("/sliders-data-by-category", {params})
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_UPCOMING_EVENTS]() {
        const params = {
            locale:window.localStorage.getItem('lang')
        };
        return new Promise((resolve, reject) => {
            ApiService.query("/soon-slider-events", {params})
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_EVENT_MAP_OR_ENTRANCE](payload) {
        const params = {
            locale:window.localStorage.getItem('lang'),
            ...payload
        };
        return new Promise((resolve, reject) => {
            ApiService.query("/map-and-seat-data-for-reserve", {params})
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }
    @Action
    [Actions.GET_SEATS_PRICES](payload) {
        const params = {
            locale:window.localStorage.getItem('lang'),
            ...payload
        };
        return new Promise((resolve, reject) => {
            ApiService.post("/selected-event-seats", params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }
    @Action
    [Actions.PURCHASE](payload) {
        const params = {
            locale:window.localStorage.getItem('lang'),
            ...payload
        };
        return new Promise((resolve, reject) => {
            ApiService.post("/pending-seats-for-reserve", params)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data);
                    reject(response);
                });
        });
    }

    @Action
    [Actions.GET_PROJECT_SETTINGS]() {
        const params = {
            locale:window.localStorage.getItem('lang'),
        };
        return new Promise((resolve, reject) => {
            ApiService.query("/site-settings", {params})
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    }
    @Action
    [Actions.CONTACT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("contact", credentials)
                .then(({ data }) => {
                    //this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, response.data);
                    reject();
                });
        });
    }
}
