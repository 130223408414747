enum Actions {
  // action types
    CONTACT="Contact",
    GET_PROJECT_SETTINGS="getProjectSettings",
  SIGNOUT="signOut",
  GET_EVENT_MAP_OR_ENTRANCE="getEventMapOrEntrance",
  GET_SEATS_PRICES="getEventPricingOfSelectedSeats",
  GET_UPCOMING_EVENTS="getUpcomingEvents",
  GET_SLIDER_EVENTS="getSliderEvents",
  GET_EVENT_BY_ID="getEventById",
    GET_CATEGORIES="getCategories",
  GET_USER ='getUser',
  EDIT_USER='editUser',
  GET_PAGES="getPages",
    SEARCH="searchViaAPi",
    ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  CHECK_TOKEN = "checkToken",
  LOGIN = "login",
  LOGIN_SU = "login_su",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  RESET_PASSWORD = "resetPassword",
  GET_PAGE_BY_SLUG ="getPageBySlug",
  GET_CATEGORY_BY_ID ="getCategoryByID",
  PURCHASE ="purchaseSelected",
  GET_USER_ORDERS="getUserOrders",
  GET_USER_WISHLIST="getUserWishlist",
  SET_USER_WISHLIST="setUserWishlist",
}

enum Mutations {
  // mutation types
  SET_CATEGORIES ="setCategories",
  SET_PAGES="setPages",
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_PAGE_BY_SLUG = "setPageBySlug",
  GET_USER_INFO="getUserInfo",
}

export { Actions, Mutations };
