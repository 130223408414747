import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Mutations, Actions} from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () => import("@/layout/Layout.vue"),
        children: [
            {
                path: "/",
                name: "dashboard",
                component: () => import("@/views/Dashboard.vue"),
            },
            {
                path: "/search",
                name: "search",
                component: () =>
                    import("@/views/pages/Search.vue"),
            },
            {
                path: "/page/:slug",
                name: "page",
                component: () =>
                    import("@/views/pages/Page.vue"),
            },
            {
                path: "/category/:id",
                name: "category",
                component: () =>
                    import("@/views/pages/Category.vue"),
            },
            {
                path: "/event/:id",
                name: "event",
                component: () =>
                    import("@/views/pages/Event.vue"),
            },

            {
                path: "/pages/profile",
                name: "profile",
                component: () => import("@/components/page-layouts/Profile.vue"),
                children: [
                    {
                        path: "overview",
                        name: "profile-overview",
                        component: () =>
                            import("@/views/crafted/pages/profile/Overview.vue"),
                    },
                    {
                        path: "orders",
                        name: "my-orders",
                        component: () =>
                            import("@/views/crafted/pages/profile/Orders.vue"),
                    },
                    {
                        path: "wishlist",
                        name: "wishlist",
                        component: () =>
                            import("@/views/crafted/pages/profile/Wishlist.vue"),
                    }
                ],
            },
            {
                path: "/",
                component: () => import("@/components/page-layouts/Auth.vue"),
                children: [
                    {
                        path: "/sign-in",
                        name: "sign-in",
                        component: () =>
                            import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
                    },
                    {
                        path: "/sign-up",
                        name: "sign-up",
                        component: () =>
                            import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
                    },
                    {
                        path: "/password-reset",
                        name: "password-reset",
                        component: () =>
                            import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
                    },
                    {
                        path: "/reset-password",
                        name: "reset-password",
                        component: () =>
                            import("@/views/crafted/authentication/basic-flow/ResetPassword.vue"),
                    },
                    {
                        path: "/contact",
                        name: "contact",
                        component: () =>
                            import("@/views/pages/Contact.vue"),
                    },

                ],
            },
        ],
    },


    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(() => {
    //store.dispatch(Actions.CHECK_TOKEN);

    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);

    //store.dispatch(Actions.VERIFY_AUTH);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export default router;
